<template>
    <!-- <v-app id="login" class="login-background"> -->
    <v-container fluid grid-list-xl class="mt-3">
        <v-layout justify-center align-center class="pb-11 pt-8">
            <v-img
                v-if="responsive"
                class="mr-2"
                src="https://utaw.blob.core.windows.net/images/ykclogo.png"
                height="181"
                width="180"
                contain
            />
            <v-img
                v-if="!responsive"
                class=""
                src="https://utaw.blob.core.windows.net/images/ykclogo.png"
                height="201"
                width="200"
                contain
            />
        </v-layout>
        <v-layout align-center justify-center>
            <v-flex xs11 md6>
                <v-card style="border-radius: 28px;" class="pa-4">
                    <v-card color="#fff" class="elevation-0">
                        <v-layout row align-center justify-center>
                            <div
                                class="font-weight-medium headline text-xs-center mt-3"
                                style="color: #4289b0;"
                            >
                                LOGIN
                            </div>
                        </v-layout>
                    </v-card>
                    <v-card-text class="pb-2">
                        <v-form>
                            <v-text-field
                                prepend-icon="mdi-account"
                                name="login"
                                label="Login"
                                type="text"
                                v-model="username"
                            ></v-text-field>
                            <v-text-field
                                prepend-icon="mdi-lock"
                                autocomplete
                                name="password"
                                label="Password"
                                id="password"
                                type="password"
                                v-model="password"
                            ></v-text-field>
                        </v-form>
                    </v-card-text>
                    <v-card-actions class="pt-0">
                        <v-btn
                            class="mb-3 mr-5"
                            style="border-radius: 20px;"
                            dark
                            :loading="loggingIn"
                            color="#4289b0"
                            block
                            v-on:click="login()"
                            >Login</v-btn
                        >
                    </v-card-actions>

                    <v-card-text v-if="invalidLogin" class="pt-0">
                        <div class="red--text mb-0">
                            {{ loginError }}
                        </div>
                    </v-card-text>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
    <!-- </v-app> -->
</template>

<script>
import paths from "../router/paths";
import { initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

export default {
    data: () => ({
        responsive: false,
        username: "",
        password: "",
        name: "",
        invalidLogin: false,
        loggingIn: false,
        loginError: "",
        pages: [],
        auth: "",
    }),
    async created() {
        window.addEventListener("keyup", (e) => this.onKeyPressed(e));

        //await this.$store.dispatch("authentication/AUTH_LOGOUT");

        this.loadPages();

        const firebaseConfig = {
            apiKey: "AIzaSyBdND2fG8XWrVbqiMElnTDEJat9NuuB8C0",
            authDomain: "yk-billing.firebaseapp.com",
            projectId: "yk-billing",
            storageBucket: "yk-billing.appspot.com",
            messagingSenderId: "232657217901",
            appId: "1:232657217901:web:2b96dfa1136bbf74c19264",
            measurementId: "G-6P4ERWB2HK",
        };

        const app = initializeApp(firebaseConfig);
        const auth = getAuth(app);
        this.auth = auth;

        this.auth.signOut().then(
            (response) => {},
            function(error) {}
        );

        // document
        //     .getElementById("main-background")
        //     .classList.remove("mainBackground");

        // document
        //     .getElementById("main-background")
        //     .classList.add("loginBackground");
    },
    mounted() {
        this.onResponsiveInverted();
        window.addEventListener("resize", this.onResponsiveInverted);
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.onResponsiveInverted);
    },
    destroyed() {
        window.removeEventListener("keyup", (e) => this.onKeyPressed(e));
    },
    methods: {
        onResponsiveInverted() {
            if (window.innerWidth < 991) {
                this.responsive = true;
            } else {
                this.responsive = false;
            }
        },
        onKeyPressed(e) {
            if (e.keyCode == 13) {
                this.login();
            }
        },
        loadPages() {
            this.pages = paths;
        },
        async login() {
            this.loggingIn = true;

            signInWithEmailAndPassword(
                this.auth,
                this.username + "@camp.yksw.org",
                this.password
            )
                .then(async (userCredential) => {
                    const user = userCredential.user;

                    const data = await this.$store.dispatch("AUTH_REQUEST", {
                        username: this.username + "@camp.yksw.org",
                        password: this.password,
                        token: "Bearer " + user.accessToken,
                    });

                    this.$router.push({
                        name: "Dashboard",
                    });
                })
                .catch((error) => {
                    this.loggingIn = false;

                    this.invalidLogin = true;
                    this.loginError = "Invalid Username Or Password";
                });
        },
        getRedirectLink(user) {
            if (!user.isAdmin) {
                const page = this.pages.find(
                    (path) =>
                        !path.path.includes(":") &&
                        user.claims.includes(path.name)
                );

                return page ? page.name : user.claims[0];
            }

            return this.$route.query.redirect
                ? this.$route.query.redirect
                : "Accounts";
        },
    },
};
</script>
<style>
.toggle-button {
    font-size: 15px;
    padding: 5px !important;
}
.toggle-button-group.v-btn-toggle .v-btn.v-btn--active {
    background-color: #dcdfe6;
}
</style>
